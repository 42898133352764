@charset "UTF-8";
@import "variables";

@media screen and (max-width: 1024px) and (orientation: portrait) {


	// /********************************************************************************

	// top  common

	// ********************************************************************************/

	body.top {
		.content-wrap {

			// width: 100%;
			// display: block;
			// z-index: 0;
			// margin: 0 auto;
			// position: relative;
			&::after {
				content: '';
				// width: 100%;
				height: 150vw;
				// position: absolute;
				// top: 0;
				// left: 0;
				// background: $base-grad;
				// z-index: -1;
			}
		}

		// 	.content-inner {
		// 		width: calc(100% - 26vw);
		// 		margin: 0 13vw;
		// 		padding-bottom: 70px;
		// 		padding-top: 40px;
		// 	}

	}

	// /********************************************************************************

	// top  fix-menu

	// ********************************************************************************/

	.top-fix-menu-wrap {

		// 	position: fixed;
		// 	bottom: 1.2vw;
		// 	right:0;
		// 	z-index: 10;
		// 	pointer-events:none;
		.scrolldown {
			// 		position: relative;
			width: 6vw;
			height: 10vh;

			// 	    margin: 0 0 4vw auto;
			// 	    transition: all .4s;
			span {
				// 	    	position: absolute;
				// 		    display: inline-block;
				left: 0;
				// 		    top: 0;
				// 		    color: #fff;
				font-size: 1.2rem;
				// 		    font-weight: 500;
				// 		    font-family: Oswald,sans-serif;
				// 		    letter-spacing: .1em;
				// 		    writing-mode: vertical-rl;
			}

			&::after {
				// 	    	content: "";
				// 		    position: absolute;
				// 		    top: 0;
				// 		    left: 0;
				width: 1px;
				// 		    height: 100%;
				// 		    background: #fff;
				// 		    animation: pathmove 2s cubic-bezier(1,0,0,1) infinite;
			}
		}

		ul.top-fix-menu {

			// 		// overflow: hidden;
			// 		pointer-events:none;
			li {
				width: 16vw;
				height: 12vw;
				// 			padding: .5vw 2.5vw .5vw .5vw;
				border-radius: 6vw 0 0 6vw;
				// 			background: rgba(#FFF, .8);
				margin: 0 0 1.5vw auto;

				// 			cursor: pointer;
				// 			pointer-events:auto;
				// 			transform:translateX(2vw);
				// 			transition:all .4s ease;
				// 			img {
				// 				display: block;
				// 				width: 100%;
				// 				height: 100%;
				// 				object-fit:contain;
				// 			}
				&:nth-of-type(2) {
					margin: 0 0 1.5vw auto;
				}

				// 			&:hover {
				// 				transform:translateX(1vw);
				// 			}


				&.ai-chat {
					&:hover {

						// 	transform:translateX(1vw);
						p.comment {
							opacity: 0;
							left: -10vw;
						}
					}

					p.comment {
						// display: none;
						width: 24vw;
						height: 5vw;
						// border-radius:.7vw;
						// position: absolute;
						// left: -10vw;
						bottom: -6vw;
						// background: rgba(#FFF, .8);
						// color: $main-color;
						// display: flex;
						// align-items:center;
						// justify-content:center;
						font-size: 1rem;

						// font-weight: 500;
						// letter-spacing: .02em;
						// opacity: 0;
						// transition:all .4s ease;
						&::after {
							// 	content:'';
							width: 4vw;
							height: 2vw;
							clip-path: polygon(100% 0, 0% 100%, 70% 100%);
							// 	background: rgba(#FFF, .8);
							// 	position: absolute;
							right: 12vw;
							bottom: calc(5vw - 1px);
							// 	display: block;
						}
					}

					.ai-chat-box-wrap {
						width: 88vw;
						// background: rgba(#FFF, .95);
						// position: absolute;
						// bottom: 0;
						right: 8vw;
						// border-radius:2vw;
						// overflow:hidden;
						// opacity: 0;
						// pointer-events:none;
						// transform:translateY(5vw);
						// transition:all .4s ease .3s;


						.ai-chat-box-close {
							width: 8vw;
							height: 8vw;
							// position: absolute;
							top: 2vw;
							right: 4vw;

							// z-index: 10;
							// cursor: pointer;
							// transition:all .3s ease;
							&::before {
								// content: '';
								// background: #FFF;
								width: 8vw;
								// height: 1px;
								// transform:rotate(-45deg);
								// position: absolute;
								// top: 50%;
								// left: 0;
								// z-index: 1;
							}

							&::after {
								// content: '';
								// background: #FFF;
								width: 8vw;
								// height: 1px;
								// transform:rotate(45deg);
								// position: absolute;
								// top: 50%;
								// left: 0;
								// z-index: 1;
							}

							// &:hover {
							// 	transform:scale(1.4);
							// }
						}

						.ai-chat-box-title {
							// background: $main-color;
							// color: #FFF;
							// font-size: 1.8rem;
							// font-weight: 500;
							// line-height: 1em;
							padding: .8em 1em;
						}

						ul.ai-chat-list {
							padding: 2vh 5vw;
							max-height: 50vh;

							// overflow-Y:scroll;
							li {
								width: 90%;

								p {
									padding: 1vh 5vw;
									// font-size: 1.5rem;
									// line-height: 1.6em;
									// background: rgba($main-color, .1);
									// position: relative;
								}

								&.answer {
									// display: flex;
									// justify-content:space-between;
									// align-items: center;
									margin: 0 10% 5vh 0;

									p {
										width: calc(100% - 12vw);

										&::before {
											content: '';
											width: 3vw;
											height: 3vw;
											// clip-path: polygon(100% 0, 0 50%, 100% 100%);
											// background: rgba($main-color, .1);
											// position: absolute;
											left: -3vw;
											top: calc(50% - 1.5vw);
											// display: block;

										}
									}

									&::before {
										content: '';
										width: 10vw;
										height: 10vw;
										// border: 1px solid $main-color;
										border-radius: 5vw;
										// display: block;
										// background: url(../images/top/top-fix-icon02.webp)no-repeat center top;
										// background-size: 130%;
									}
								}

								&.question {
									margin: 0 5% 5vh 5%;

									p {
										width: 100%;
										margin-left: auto;

										&::before {
											content: '';
											width: 3vw;
											height: 3vw;
											// clip-path: polygon(0 0, 100% 50%, 0 100%);
											// background: rgba($main-color, .1);
											// position: absolute;
											right: -3vw;
											top: calc(50% - 1.5vw);
											// display: block;
										}
									}
								}
							}
						}

						.ai-chat-box-write {
							// width: 100%;
							height: 12vh;
							padding: 1vh 4vw;

							// background: #EFEFEF;
							// p {
							// 	color: #AAA;
							// 	font-size: 1.6rem;
							// }
							span {
								width: calc(100% - 8vw);
								// color: #AAA;
								// margin-top: auto;
								// font-size: 1rem;
								// line-height: 1.5em;
								// letter-spacing: 0;
								// display:block;
								// position: absolute;
								left: 4vw;
								bottom: 1vh;
							}
						}

						// &.is-active {
						// 	opacity: 1;
						// 	pointer-events:auto;
						// 	transform:translateY(0);							
						// }
					}

					&.active {
						margin: 0 0 6vw auto;

						p.comment {
							opacity: 1;
							left: -11vw;
						}
					}

					&.lock {
						&:hover {
							transform: translateX(2vw);
						}
					}
				}

				&.worksheet {
					width: 40vw;
					// 				min-height: 8vw;
					// 				padding: .5vw .2vw .5vw 6vw;
					background: unset;
					border-radius: 0;
					// 				margin-bottom: 0;
					transform: translateX(15vw);

					// 				cursor:auto;
					img {
						width: 100%;
						height: 100%;
						// 					object-fit:contain;
						position: relative;
						left: unset;
						top: unset;
						// 					cursor: pointer;
					}

					p.question {
						display: none;
						// 					font-size: 1.5rem;
						// 					line-height: 1.2em;
						// 					font-weight: 700;
						// 					border-bottom: 1px solid $main-color;
						// 					padding-bottom: .2em;
						// 					margin-bottom: .2em;
						// 					&::before {
						// 						content:'Q.';
						// 						display: inline-block;
						// 						margin-right: .4em;
						// 						font-size: 1.8rem;
						// 						font-weight: 700;
						// 						color:$main-color;
						// 					}
					}

					.worksheet-check-list {
						display: none;
						// 					flex-wrap:wrap;
						// 					opacity:0;
						// 					form {
						// 						line-height:1.3em;
						// 					}
						// 					.worksheet-label {
						// 						display: inline-block;
						// 						label {
						// 							font-size: 1.2rem;
						// 							font-weight: 700;
						// 							letter-spacing: 0;
						// 							line-height: 1.2em;
						// 							margin-right: 1em;
						// 						}
						// 						input {
						// 							margin: 0 .2vw;
						// 						}
						// 						input[type="text" i] {
						// 							min-width: 30em;
						// 							margin-left: 1em;
						// 						}
						// 						span {
						// 							font-size: 1rem;
						// 							font-weight: 500;
						// 						}
						// 					}
					}

					// 				.send {
					// 					width: 6vw;
					// 					height: 2vw;
					// 					display: inline-flex;
					// 					align-items:center;
					// 					justify-content:center;
					// 					font-size: 1.5rem;
					// 					font-weight: 700;
					// 					color: #FFF;
					// 					background: $main-color;
					// 					position: absolute;
					// 					bottom: .5vw;
					// 					right: 2vw;
					// 					border: none;
					// 					border-radius:.4vw;
					// 					box-shadow: 3px 2px 4px rgba(#000, .2);
					// 				}
					// 				.submit-text {
					// 					display: none;
					// 					p{
					// 						font-size: 1.6rem;
					// 						font-weight: 500;
					// 						padding: .5em 0;
					// 					}
					// 				}
					.worksheet-close {
						display: none;
						// 					width: 2.2vw;
						// 					height: 2.2vw;
						// 					border-radius:50%;
						// 					background: #FFF;
						// 					box-shadow: 3px 2px 4px rgba(#000, .2);
						// 					position: absolute;
						// 					top: 1vw;
						// 					right: 2vw;
						// 					cursor: pointer;
						// 					&::before{
						// 						content: '';
						// 						background: $main-color;
						// 						width: 1.2vw;
						// 						height: 1px;
						// 						transform:rotate(-45deg);
						// 						position: absolute;
						// 						top: 50%;
						// 						left: .5vw;
						// 						z-index: 1;
						// 					}
						// 					&::after{
						// 						content: '';
						// 						background: $main-color;
						// 						width: 1.2vw;
						// 						height: 1px;
						// 						transform:rotate(45deg);
						// 						position: absolute;
						// 						top: 50%;
						// 						left: .5vw;
						// 						z-index: 1;
						// 					}
						// 					&:hover {
						// 						background: #FAFAFA;
						// 					}
					}

					&:hover {
						transform: translateX(0);
					}

					&.active {
						width: 40vw;

						// 					transform:translateX(0);
						// 					.worksheet-check-list {
						// 						opacity:1;
						// 						transition:all .1s ease .4s;
						// 					}
						img {
							width: 100%;
							height: 100%;
							left: unset;
						}

						// 					&:hover {
						// 						transform:translateX(0);
						// 					}
					}
				}
			}
		}

		// 	&.hide-out{
		// 		.scrolldown{
		// 			opacity: 0;
		// 		}
		// 	}
	}


	.worksheet-mb-wrap {
		position: fixed;
		bottom: -120%;
		left: 8vw;
		flex-direction: column;
		justify-content: center;
		width: calc(100% - 16vw);
		height: 44vh;
		max-height: 300px;
		background: rgba(#fff, .8);
		border-radius: 4vw 4vw 0 0;
		padding: 8vw 4vw 10vw;
		z-index: 22;
		pointer-events: none;
		transition: all .5s;

		p.question {
			font-size: 1.5rem;
			line-height: 1.2em;
			font-weight: 700;
			border-bottom: 1px solid $main-color;
			padding-bottom: .5em;
			margin-bottom: .5em;

			&::before {
				content: 'Q.';
				display: inline-block;
				margin-right: .4em;
				font-size: 1.8rem;
				font-weight: 700;
				color: $main-color;
			}
		}

		.worksheet-check-list {
			display: flex;
			flex-wrap: wrap;

			form {
				line-height: 1.3em;
			}

			.worksheet-label {
				display: inline-block;
				margin-bottom: 3px;

				label {
					font-size: 1.2rem;
					font-weight: 700;
					letter-spacing: 0;
					line-height: 1.2em;
					margin-right: 1em;
				}

				input {
					margin: 0 .2vw;
				}

				input[type="text"i] {
					min-width: 20em;
					margin-left: 1em;
				}

				span {
					font-size: 1rem;
					font-weight: 500;
				}
			}
		}

		.send {
			width: 30vw;
			height: 6vw;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 1.3rem;
			font-weight: 700;
			color: #FFF;
			background: $main-color;
			position: absolute;
			bottom: 5vw;
			right: 50%;
			transform: translateX(50%);
			border: none;
			border-radius: .4vw;
			box-shadow: 3px 2px 4px rgba(#000, .2);
		}

		.submit-text {
			display: none;

			p {
				font-size: 1.6rem;
				font-weight: 500;
				padding: .5em 0;
			}
		}

		.worksheet-close {
			width: 8vw;
			height: 8vw;
			border-radius: 50%;
			background: #FFF;
			box-shadow: 3px 2px 4px rgba(#000, .2);
			position: absolute;
			top: 3vw;
			right: 4vw;
			cursor: pointer;

			&::before {
				content: '';
				background: $main-color;
				width: 5vw;
				height: 1px;
				transform: rotate(-45deg);
				position: absolute;
				top: 50%;
				left: 1.5vw;
				z-index: 1;
			}

			&::after {
				content: '';
				background: $main-color;
				width: 5vw;
				height: 1px;
				transform: rotate(45deg);
				position: absolute;
				top: 50%;
				left: 1.5vw;
				z-index: 1;
			}

			&:hover {
				background: #FAFAFA;
			}
		}

		&::before {
			content: '';
			position: absolute;
			background-color: rgba(#000, .2);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			transition: all .5s;
		}

		&.active {
			bottom: 0;
			pointer-events: auto;
		}
	}

	.worksheet-mb-bg {
		position: absolute;
		background-color: rgba(#000, .4);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		transition: all .5s;
		z-index: 21;
		pointer-events: none;

		&.active {
			opacity: 1;
		}
	}


	// @keyframes pathmove {
	//     0% {
	//         transform: scale(1,0);
	//         transform-origin: 0 0;
	//     }

	//     50% {
	//         transform: scale(1,1);
	//         transform-origin: 0 0;
	//     }

	//     50.1% {
	//         transform: scale(1,1);
	//         transform-origin: 0 100%;
	//     }

	//     100% {
	//         transform: scale(1,0);
	//         transform-origin: 0 100%;
	//     }
	// }

	// // /********************************************************************************

	// // top  緊急のお知らせ

	// // ********************************************************************************/


	.top-attention_topics-wrap {
		// 	position: fixed;
		bottom: 13.5vw;
		width: 80%;

		// 	left:0;
		// 	z-index: 10;
		// 	transform:translateX(0);
		// 	transition:all .2s ease;
		ul.top-attention_topics-list {
			li {

				// 			margin-bottom: 4px;
				a {
					// 				display: inline-flex;
					// 				align-items:center;
					// 				background: $yellow-color;
					padding: 2px 3vw;
					font-size: 1.1rem;

					// 				line-height: 1.4em;
					// 				font-weight: 700;
					// 				min-height: 2.2vw;
					// 				// border-radius: 0 1vw 1vw 0;
					// 				box-shadow: 8px 4px 10px rgba(#000, .2);
					// 				position: relative;
					span.date {
						// 					display: inline-block;
						font-size: 1.2rem;
						// 					font-weight: 700;
						margin-right: 7px;
					}
				}
			}
		}

		// 	&.hide-out {
		// 		transform:translateX(-120%);
		// 	}
	}


	// // /********************************************************************************

	// // top  mainvisual

	// // ********************************************************************************/

	.top-mainvisual-section {

		// width: 100%;
		// height: calc(100vh - 16.5vw);
		// margin: 9vw auto 15vw;
		// position: relative;
		.top-mainvisual-wrap {
			width: 75vw;
			max-width: 35vh;
			height: fit-content;
			// margin: 0 auto;
			// position: absolute;
			// top: 50%;
			// left:50%;
			// transform:translate(-50% , -50%);
			// z-index: 1;
			// cursor: pointer;

			.top-mainvisual-bg {
				// fill: #FFF;
				// display: block;
				width: 100%;
				height: 85vh;
				// position: absolute;
				// top: 50%;
				// left:50%;
				// transform:translate(-50% , -50%);
				// z-index: -1;
			}

			.top-mainvisual {
				// display: block;
				// width: 100%;
				// height: auto;
				// transition:all .3s ease ;
			}

			.top-mainvisual-introduction {
				// width: 100%;
				// height: 100%;
				padding: 4vw 8vw;
				// position: absolute;
				// top: 50%;
				// left:50%;
				// // background: rgba(#FFF, .2);
				// display: flex;
				// flex-direction:column;
				// align-items:center;
				// justify-content:center;
				// opacity: 0;
				// visibility: hidden;
				transform: translate(-50%, -50%);

				// transition:all .4s ease 0s;
				p.top-mainvisual-introduction-title {
					// width: 100%;
					font-size: 5vw;
					// font-weight: 500;
					// color: $main-color;
					// text-align: center;
					padding-bottom: 3vh;
					// border-bottom: 1px solid $main-color;
				}

				ul.top-mainvisual-keyword {
					// display: flex;
					// align-items:center;
					// justify-content:center;
					// flex-wrap:wrap;
					padding: 2vh 1vw 1.5vh;

					li {
						// width: fit-content;
						// font-size: 3rem;
						font-size: 3.5vw;
						font-weight: 500;
						// line-height: 1.3em;
						// color: $main-color;
						// margin:0 .6em .5em 0;
						// letter-spacing: 0;
					}
				}

				p.top-mainvisual-introduction-text {
					font-size: 3vw;
					text-align: justify;
					letter-spacing: 0.05em;
					// line-height: 1.6em;
					// font-weight: 500;
				}
			}

			&:hover {
				.top-mainvisual {
					// opacity: .4;
					// filter: blur(3px);
				}

				.top-mainvisual-introduction {
					// opacity: 1;
					// visibility: visible;
					// transform:translate(-50% , -50%);
					// transition:all .5s ease .3s;
				}
			}
		}

		img.top-mainvisual-university {
			// display: block;
			width: 92vw;
			max-width: 44vh;
			// margin: 0 auto;
			// position: absolute;
			// top: 50%;
			// left:50%;
			// transform:translate(-50% , -50%);
		}

		p.top-mainvisual-attention {
			// position: absolute;
			// bottom:-6vw;
			// left:2vw;
			font-size: 2.5vw;
			// letter-spacing: .05em;
			// color: #FFF;
		}
	}






	// // /********************************************************************************

	// // top  navi-section 

	// // ********************************************************************************/

	.top-navi-section {

		// 	width: 100%;
		// 	padding: 3vw 8vw 12vw;
		ul.top-target-navi-list {

			// 		display: flex;
			// 		flex-wrap:wrap;
			// 		justify-content:space-between;
			li {
				// 			width: 30%;
				margin-bottom: 14vw;

				// 			transform:translateY(4vw);
				// 			opacity: 0;
				// 			transition:all .6s ease;
				a {
					position: relative;
					// 				width:100%;
					height: 11vh;
					min-height: 90px;
					// 				border-radius: 4vw;
					flex-direction: column;
					// 				display: flex;
					// 				align-items:center;
					// 				justify-content:center;
					// 				padding: 0 14% 0 10%;
					// 				box-shadow: 8px 4px 10px rgba(#000, .2);

					span.icon {
						// 					display: block;
						width: 80%;
						// 					height: 100%;
						// 					img {
						// 						display: block;
						// 						width: 100%;
						// 						height: 100%;
						// 						object-fit:contain;
						// 					}
					}

					p {
						position: absolute;
						bottom: -1.5vw;
						left: 50%;
						transform: translate(-50%, 100%);
						width: 100%;
						// 					text-align: center;
						font-size: 1.2rem;
						// 					font-weight: 600;
						// 					line-height: 1.5em;
						// 					color: #FFF;
					}
				}

				&.navi01 {
					// 				order:0;
					transition-delay: 0s;
					// 				a {
					// 					background: $target-color01;
					// 				}
				}

				&.navi02 {
					// 				order:3;
					transition-delay: .2s;
					// 				a {
					// 					background: $target-color02;
					// 				}
				}

				&.navi03 {
					// 				order:1;
					transition-delay: .1s;
					transform: translateY(4vw);
					// 				a {
					// 					background: $target-color03;
					// 				}
				}

				&.navi04 {
					// 				order:4;
					transition-delay: .27s;
					transform: translateY(4vw);
					// 				a {
					// 					background: $target-color04;
					// 				}
				}

				&.navi05 {
					// 				order:2;
					transition-delay: .17s;
					// 				a {
					// 					background: $target-color05;
					// 				}
				}

				&.navi06 {
					// 				order:5;
					transition-delay: .37s;
					// 				a {
					// 					background: $target-color06;
					// 				}
				}
			}

			&.is-show {
				li {

					// 				opacity: 1;
					// 				&.navi01 {
					// 					transform:translateY(0);
					// 				}
					// 				&.navi02 {
					// 					transform:translateY(0);
					// 				}
					&.navi03 {
						transform: translateY(0);
					}

					&.navi04 {
						transform: translateY(0);
					}

					// 				&.navi05 {
					// 					transform:translateY(0);
					// 				}
					// 				&.navi06 {
					// 					transform:translateY(0);
					// 				}
				}
			}
		}

		ul.top-category-navi-list {
			// 		padding-top: 7vw;
			// 		display: flex;
			// 		justify-content:space-between;
			flex-direction: column;

			li {
				margin-bottom: 6vw;
				transform: translateY(0);

				// 			opacity: 0;
				// 			transition:all .5s ease .2s;
				a {
					display: flex;
					// 				// flex-direction:column;
					justify-content: center;
					align-items: center;
					// 				display: block;
					width: 100%;
					height: 22vw;
					min-height: 22vw;
					border-radius: 11vw;
					// 				background: rgba(#FFF, .45);
					// 				box-shadow: 8px 4px 10px rgba(#000, .2);
					// 				padding: 6vw 1vw;
					// 				transform:translateY(0);
					// 				transition:all .4s ease;
					// 				position: relative;

					span.hover-title {
						// 					display: block;
						width: 30%;
						margin: 0;
						position: relative;
						top: unset;
						left: unset;
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
						// 					transition:all .15s ease 0s;
					}

					p {
						width: 30%;
						position: relative;
						top: unset;
						left: unset;
						transform: translateY(0);

						&.hover-text {
							display: none;
							// 						width: 80%;
							// 						font-size: 1.4rem;
							// 						font-weight: 500;
							// 						line-height: 1.7em;
							// 						color: #FFF;
							// 						top: 50%;
							// 						left:10%;
							// 						opacity: 0;
							// 						visibility: hidden;
							// 						transform:translateY(2vw);
							// 						transition:all .15s ease 0s;
						}
					}

					// 				img {
					// 					display: block;
					// 					width: 100%;
					// 					height: 100%;
					// 					object-fit:contain;
					// 				}
					&::after {
						// 					content:'';
						// 					width: 100%;
						// 					height: 100%;
						// 					position: absolute;
						// 					top: 0;
						// 					left: 0;
						border-radius: 11vw;
						// 					z-index: -1;
						opacity: 1;
						// 					transition:all .3s ease 0s;

					}

					&:hover {
						transform: translateY(0);
						min-height: 22vw;

						// 					opacity: 1;
						span.hover-title {
							// 						opacity: 1;
							transform: translateY(0);
							// 						transition:all .2s ease .5s;
							// 						visibility: visible;
						}

						// p {
						// &.hover-text {
						// 							opacity: 1;
						// transform:translateY(0);
						// 							transition:all .2s ease .5s;
						// 							visibility: visible;
						// }
						// }
						// 					&::after {
						// 						opacity: 1;
						// 						transition:all .4s ease .5s;
						// 					}
					}
				}

				&:nth-of-type(2n - 1) {
					margin-top: 0;
					transform: translateY(0);

					a:hover {
						transform: translateY(0);
					}
				}

				// 			&:nth-of-type(1) a::after {
				// 				background: url(../images/top/top-category-navi01.webp)no-repeat center center;
				// 				background-size: cover;
				// 			}
				// 			&:nth-of-type(2) a::after {
				// 				background: url(../images/top/top-category-navi02.webp)no-repeat center center;
				// 				background-size: cover;
				// 			}
				// 			&:nth-of-type(3) a::after {
				// 				background: url(../images/top/top-category-navi03.webp)no-repeat center center;
				// 				background-size: cover;
				// 			}
				// 			&:nth-of-type(4) a::after {
				// 				background: url(../images/top/top-category-navi04.webp)no-repeat center center;
				// 				background-size: cover;
				// 			}
			}

			// 		&.is-show {
			// 			li {
			// 				opacity: 1;
			// 				transform:translateY(0);
			// 			}
			// 		}
		}
	}





	// // /********************************************************************************

	// // top  cyberpark-section 

	// // ********************************************************************************/


	.top-cyberpark-section {
		// 	width: 100%;
		padding: 30vh 8vw 8vw 8vw;

		// 	// background: linear-gradient(140deg, rgba(33,80,177,.9) 0%, rgba(103,128,222,.75) 62%, rgba(17,100,168,1) 100%);
		// 	background: linear-gradient(140deg, rgba(41,114,176,.9) 0%, rgba(108,142,232,.75) 60%, rgba(54,110,142,1) 100%);
		// 	overflow:hidden;
		// 	position: relative;
		// 	> * {
		// 		position: relative;
		// 		z-index: 1;
		// 	}
		h3.top-cyberpark-title {
			width: 40%;
			// 		position: absolute;	
			top: 12vh;
			left: 50%;
			transform: translateX(-50%);
			// 		opacity: 0;
			// 		z-index: 2;
			// 		img {
			// 			display: block;
			// 			width: 100%;
			// 			height: 100%;
			// 			object-fit:contain;
			// 		}
		}

		.top-cyberpark-map-wrap {
			width: 128%;
			// 		margin-left: auto;	
			// 		position: relative;
			transform: translateY(0) translateX(-10%);
			// 		opacity: 0;


			// 		img {
			// 			display: block;
			// 			width: 100%;
			// 			height: 100%;
			// 			object-fit:contain;
			// 		}
			.top-cyberpark-tag {
				// 			position: absolute;
				width: 20vw;

				// 			opacity: 0;
				// 			cursor: pointer;
				// 			transform:translateY(-6vw);
				// 			img {
				// 				display: block;
				// 				width: 60%;
				// 				margin: 0 auto .5vw;
				// 				transition:all .2s ease;
				// 			}
				// 			p {
				// 				font-size: 1.2rem;
				// 				line-height: 1.3em;
				// 				background: #FFF;
				// 				border: 1px solid #000;
				// 				letter-spacing: 0;
				// 				font-weight: 500;
				// 				width: 100%;
				// 				text-align: center;
				// 				padding: .2em 0;
				// 				box-shadow: 0 2px 6px rgba(0, 0, 0, .4);
				// 			}
				&.tag01 {
					top: 15%;
					left: 67%;
				}

				&.tag02 {
					top: 50%;
					left: 60%;
				}

				&.tag03 {
					top: 30%;
					left: 14%;
				}

				&.tag04 {
					top: 44%;
					left: 36%;
				}

				&.tag05 {
					top: 18%;
					left: 30%;
				}
				&.tag06 {
					top: 13%;
					left: 49%;
				}

				// 			&:hover {
				// 				img {
				// 					width: 70%;
				// 				}
				// 			}
			}
		}


		.top-cyberpark-article-wrap {
			margin: 0 auto;
			// 		width: calc(100% - 8vw);
			min-height: 160px;
			height: 56vw;
			// 		border-radius: 2vw ;
			// 		background: rgba(#FFF, .3);
			// 		box-shadow: 8px 4px 10px rgba(#000, .2);
			margin-top: 6vw;
			// 		// padding: 3vw 4vw 4vw;
			// 		transform:translateX(-5vw);
			// 		opacity: 0;
			// 		position: relative;

			.top-cyberpark-article {
				// 			width: 100%;
				// 			height: 100%;
				// 			position: absolute;
				// 			top: 0;
				// 			left:0;
				padding: 4vw;

				// 			opacity: 0;
				// 			pointer-events:none;
				// 			transform:translateY(10vw);
				// 			transition: all .5s;


				h4.top-cyberpark-article-title {
					font-size: 2rem;
					// 				font-weight: 500;
					// 				color: #FFF;
					// 				border-bottom:1px solid #FFF;
					// 				padding-bottom: .3em;
				}

				ul.top-cyberpark-article-list {

					// 				padding-top: 2vw;
					li {

						// 					margin: 0 .2vw;
						a {
							// 						display: block;
							// 						position: relative;

							// 						.top-cyberpark-photo{
							// 							position: relative;
							// 							overflow: hidden;
							// 							img{
							// 								width: 100%;
							// 								transition: all .3s;
							// 							}
							// 						}

							.top-cyberpark-movie-photo {

								// 							position: relative;
								// 							overflow: hidden;
								// 							img{
								// 								width: 100%;
								// 								transition: all .3s;
								// 							}
								// 							&::before{
								// 								content: '';
								// 								position: absolute;
								// 								background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.5) 100%);
								// 								width: 100%;
								// 								height: 100%;
								// 								top: 0;
								// 								left: 0;
								// 								z-index: 1;
								// 							}
								&::after {
									// 								content: '';
									// 								position: absolute;
									// 								background: url("../images/corpo/corpo-movie-icon.webp") no-repeat;
									// 								background-size: contain;
									width: 12vw;
									height: 12vw;
									// 								top: 50%;
									// 								left: 50%;
									// 								transform: translate(-50%,-50%);
									// 								z-index: 2;
								}
							}


							p.top-cyberpark-movie-title {
								// position: absolute;
								// bottom: 1vw;
								// left: 50%;
								// transform: translateX(-50%);
								font-size: 1.2rem;
								font-weight: 500;
								width: 100%;

								// color: #fff;
								// z-index: 1;
							}

							// 						&:hover{
							// 							opacity: 1;
							// 							.corpo-movie-photo{
							// 								img{
							// 									transform: scale(1.1);
							// 								}
							// 							}
							// 						}
						}
						&.tweet {
							// padding:0;
							max-height: inherit;
							// width: 100%;
							// overflow:hidden;

							// .twitter-timeline {
							// 	width: 100% !important;
							// 	margin: 0 auto;
							// }
							// .twitter-tweet {
							// 	margin: 0 !important;
							// 	transform:translateY(-1.5vw);
							// }
							// iframe {
							// 	// width: 100% !important;
							// 	// transform:scale(.85);
							// }
						}
					}

				}

				// 			&.active {
				// 				opacity: 1;
				// 				pointer-events:auto;
				// 				transform:translateY(0);
				// 			}
			}
			&.long {
				height:82vw;
				transition: all .3s ease 0s;
			}
		}



		&::before {
			// 		content:"";
			// 		width: 0;
			height: calc(100% - 10vw);
			// 		height: 100%;
			// 		position: absolute;
			top: 10vw;
			// 		left:50%;
			// 		z-index: 0;
			// 		display: block;
			border-radius: 5vw 5vw 0 0;
			// 		background: rgba(#FFF, .35);
			// 		box-shadow: 8px 4px 10px rgba(#000, .2);
			// 		opacity: 0;
		}

		&.is-show {

			// 		h3.top-cyberpark-title {
			// 			opacity: 1;
			// 			transition:all .7s ease 1s;
			// 		}
			.top-cyberpark-map-wrap {
				transform: translateY(0) translateX(-10%);
				// 			opacity: 1;
				// 			transition:all .5s ease-out .8s;
				// 			.top-cyberpark-tag {
				// 				opacity: 1;
				// 				transform:translateY(0);
				// 				transition:all .3s ease 1.3s;
				// 				&.tag01 {
				// 					transition-delay: 1.3s;
				// 				}
				// 				&.tag02 {
				// 					transition-delay: 1.4s;
				// 				}
				// 				&.tag03 {
				// 					transition-delay: 1.5s;
				// 				}
				// 				&.tag04 {
				// 					transition-delay: 1.6s;
				// 				}
				// 				&.tag05 {
				// 					transition-delay: 1.7s;
				// 				}
				// 			}
			}

			// 		.top-cyberpark-article-wrap {
			// 			opacity: 1;
			// 			transform:translateX(0);
			// 			transition:all .5s ease 2s;
			// 		}
			&::before {
				// 			content:"";
				left: 7.5%;
				width: 85%;
				// 			opacity: 1;
				// 			transition:all .3s ease .3s;
			}
		}
	}


	.slide-arrow {
		// 	position: absolute;
		// 	top: 50%;
		// 	z-index: 1;
		// 	cursor: pointer;
		transform: translateY(-50%);

		// 	transition: all .4s;
		span {
			background: #fff;
			// 		position: relative;
			// 		display: block;
			// 		border: solid 1px #fff;
			// 		width: 30px;
			// 		height: 30px;
			// 		transition: all .4s;
			// 		&::before{
			// 			content: '';
			// 			position: absolute;
			// 			width: 10px;
			// 			height: 10px;
			// 			top: 44%;
			// 			transform: translateY(-50%);
			// 			transition: all .4s;
			// 		}
		}

		&.prev-arrow {
			left: -5%;

			span {
				&::before {
					// 				right: 10px;
					border-left: solid 1px $main-color;
					border-bottom: solid 1px $main-color;
					// 				transform: rotate(45deg)  translateY(-50%);
				}
			}
		}

		&.next-arrow {
			right: -5%;

			span {
				&::before {
					// 				left: 3px;
					border-top: solid 1px $main-color;
					border-right: solid 1px $main-color;
					// 				transform: rotate(45deg)  translateY(-50%);
				}
			}
		}

		// 	&:hover{
		// 		span{
		// 			background: #fff;
		// 		}
		// 		&.prev-arrow{
		// 			span{
		// 				&::before{
		// 					border-left: solid 1px $main-color;
		// 					border-bottom: solid 1px $main-color;
		// 				}
		// 			}
		// 		}
		// 		&.next-arrow{
		// 			span{
		// 				&::before{
		// 					border-top: solid 1px $main-color;
		// 					border-right: solid 1px $main-color;
		// 				}
		// 			}
		// 		}
		// 	}
	}


	// // /********************************************************************************

	// // top  #Find Your Dream section 

	// // ********************************************************************************/

	.top-find-section {
		// 	width: 100%;
		padding: 30vw 6vw;

		// 	background: #FFF;
		// 	position: relative;
		.top-find-section-head {
			// 		display: flex;
			flex-direction: column;
			// 		justify-content:space-between;
			// 		align-items:center;
			padding: 0 2vw;

			h3.top-find-title {
				width: 100%;
				// 			color: $main-color;
				font-size: 2rem;

				// 			font-weight: 500;
				// 			line-height: 1.7em;
				span {
					// 				display: block;
					// 				font-family: "Oswald", sans-serif;
					// 				font-optical-sizing: auto;
					// 				font-weight: 500;
					font-size: 10vw;
					// 				line-height: 1.2em;
					// 				letter-spacing: .08em;
				}
			}

			p {
				width: 100%;
				// 			color: $main-color;
				font-size: 1.2rem;
				text-align: center;
				// 			font-weight: 500;
				// 			line-height: 1.7em;
				padding-top: .8em;
			}
		}

		ul.top-find-word-list {
			// 		display: flex;
			// 		justify-content:center;
			// 		align-items:center;
			// 		flex-wrap:wrap;
			padding: 6vw 0 10vw;

			li {
				// 			width: fit-content;
				height: 6vw;
				border-radius: 0;
				// 			padding: 0 1.5em;
				// 			display: flex;
				// 			justify-content:center;
				// 			align-items:center;
				// 			color: #FFF;
				font-size: 1.2rem;
				// 			line-height: 1em;
				// 			letter-spacing: .02em;
				// 			font-weight: 500;
				margin: 0 2vw 2vw 0;

				// 			cursor: pointer;
				// 			background: linear-gradient(110deg, rgba(118,157,231,1) 0%, rgba(95,100,203,1) 35%, rgba(111,147,207,1) 60%, rgba(102,125,214,1) 100%);
				// 			transition:all .3s ease;
				// 			&::before {
				// 				content:'#';
				// 				display: inline-block;
				// 				margin-right: .3em;
				// 			}
				// 			&:hover {
				// 				transform:scale(1.08);
				// 			}
				// 			&.is-active {
				// 				background: $main-color;
				// 			}
				a {
					color: #fff;
				}
			}
		}

		.page-program-link {
			padding-top: 14vh;
			margin-top: -14vh;
		}

		.top-find-program-list-wrap {
			// 		display: flex;
			// 		justify-content:space-between;
			// 		padding: 2vw 4vw 0;
			flex-direction: column;

			dl.top-find-program-list {
				width: 100%;
				// 			border: 1px solid $main-color;
				padding: 4vw 4vw 3vw;
				margin-bottom: 6vw;

				// 			position: relative;
				dt {
					// 				width: 75%;
					font-size: 1.6rem;

					// 				font-weight: 500;
					// 				text-align: center;
					// 				color: $main-color;
					// 				position: absolute;
					// 				top: -1em;
					// 				left:50%;
					// 				transform:translateX(-50%);
					// 				background: #FFF;
					span {
						// 					display: inline-block;
						font-size: 2.4rem;
						// 					font-family: "Oswald", sans-serif;
						// 					font-optical-sizing: auto;
						// 					font-weight: 500;
						// 					margin-right: .05em;
					}
				}

				dd {
					ul {
						li {
							margin-bottom: .4em;

							a {
								font-size: 1.1rem;
								// 							line-height: 1.6em;
								// 							letter-spacing: .02em;
								// 							font-weight: 500;
								// 							display: block;
								padding-left: 3vw;

								// 							padding-bottom: .3em;
								// 							position: relative;
								// 							opacity: .1;
								// 							pointer-events:none;
								// 							transition:all .4s ease;
								&::before {
									position: absolute;
									top: 50%;
									left: 0;
									transform: translateY(-50%);
									// 								content:'';
									// 								display: inline-block;
									// 								width: .8vw;
									// 								height: .8vw;
									// 								border-radius: 50%;
									// 								background: $main-color;
									margin-right: 0;
									margin-left: 0;
								}

								// 							&::after {
								// 								content:'';
								// 								width: 0;
								// 								height: 1px;
								// 								background: $main-color;
								// 								position: absolute;
								// 								left:1em;
								// 								bottom:0;
								// 								transition:all .4s ease;
								// 							}
								&:hover {
									// 								opacity: 1;
									// 								color: $main-color;
									padding-left: 3vw;
								}
							}

							&.show a {

								// 							opacity: 1;
								// 							pointer-events:auto;
								&::before {
									// 								content:'';
									// 								display: inline-block;
									// 								width: .8vw;
									// 								height: .8vw;
									// 								border-radius: 50%;
									// 								background: $main-color;
									margin-right: 0;
									margin-left: 0;
								}

								// 							&::after {
								// 								content:'';
								// 								width: calc(100% - 1em);
								// 							}
							}
						}
					}
				}
			}
		}

		.top-find-textscroll {

			// 		width: 100%;
			// 		padding: 2px 0;
			// 		overflow:hidden;
			// 		position: absolute;
			// 		left:0;
			// 		pointer-events:none;
			// 		opacity: .8;
			// 		&.scroll-position01 {
			// 			top: 5vw;
			// 		}
			// 		&.scroll-position02 {
			// 			bottom: 5vw;
			// 		}
			p {
				width: 300vw;
				height: 24vw;

				svg {
					width: 300vw;
					height: 24vw;

					// 				display: block;
					text {
						// 					width: 100%;
						// 					height: 100%;
						font-size: 10vw;
						// 					letter-spacing: .2em;
						// 					line-height: 1em;
						// 					font-family: "Oswald", sans-serif;
						// 					font-optical-sizing: auto;
						// 					font-weight: 500;
						// 					white-space:nowrap;
						transform: translateY(25%);
					}
				}

				// 			&.textscroll01 {
				// 				transform: translateX(0);
				animation: slide-move01 40s linear infinite;

				// 			}
				&.textscroll02 {
					margin-top: -24vw;
					// 				transform: translateX(0);
					animation: slide-move02 40s linear infinite;
				}
			}
		}
	}


	@keyframes slide-move01 {
		0% {
			transform: translateX(0);
		}

		50% {
			transform: translateX(-300vw);
			opacity: 1;
		}

		50.001% {
			transform: translateX(-300vw);
			opacity: 0;
		}

		50.002% {
			transform: translateX(300vw);
			opacity: 0;
		}

		50.003% {
			transform: translateX(300vw);
			opacity: 1;
		}

		100% {
			transform: translateX(0);
		}
	}

	@keyframes slide-move02 {
		0% {
			transform: translateX(300vw);
		}

		50% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-300vw);
		}
	}


	.top-news-section {

		// 	padding: 8vw 10vw 7vw;
		.top-news-wrap {
			// 		width: 100%;
			padding: 14vw 6vw;

			// 		position: relative;
			// 		z-index: 2;
			&::before {
				// 			content:'';
				width: 100%;
				height: 100%;
				border-radius: 100vw;
				// clip-path: xywh(0 0 100% 100% round 50% 50% 0 0);
				// 			background: rgba(#FFF, .35);
				// 			// box-shadow: 8px 4px 10px rgba(#000, .2);
				// 			position: absolute;
				// 			top: 0;
				// 			left:0;
				// 			z-index: -1;
			}

			// &::after {
			// 	display: none;
			// 	// 			content:'';
			// 	width: 100%;
			// 	height: 50%;
			// 	clip-path: xywh(0 0 100% 100% round 0 0 50% 50%);
			// 	// 			background: rgba(#FFF, .35);
			// 	// 			// box-shadow: 8px 4px 10px rgba(#000, .2);
			// 	// 			position: absolute;
			// 	top: unset;
			// 	bottom: 0;
			// 	// 			right:0;
			// 	// 			z-index: -1;
			// }

			.event-calender-link {
				// position: absolute;
				bottom:-4vh;
				left:calc(50% - 35vw);
				a {
					width: 70vw;
					height: 6vh;
					// display:flex;
					// justify-content: center;
					// align-items: center;
					font-size: 1.5rem;
					// font-weight: 500;
					// letter-spacing:.02em;
					// color:#FFF;
					// line-height: 1em;
					// background:$main-color;
					// border:1px solid #FFF;
					border-radius:1vw;
					// &::before {
					// 	content:'';
					// 	width: 1.7em;
					// 	height: 1.7em;
					// 	background:url(../images/top/icon-calender.svg)no-repeat center center;
					// 	background-size: contain;
					// 	display:inline-block;
					// 	margin-right:.3em;
					// }
					// &:hover {
					// 	opacity: 1;
					// 	background:$main-color;
					// 	padding-left:.5vw;
					// }
				}
				&.sub {
					bottom:-10vw;
					left:-2vw;
					top:inherit;
					right:inherit;
					// transition: all .3s ease;
					// &:hover {
					// 	transform:scale(1.1);
					// }
					a {
						width: 26vw;
						height: 26vw;
						// display:flex;
						// flex-direction: column;
						font-size: 1.5rem;
						// font-weight: 500;
						// letter-spacing:.02em;
						// text-align: center;
						// color:#FFF;
						// line-height: 1.3em;
						// background:$main-color;
						// border:1px solid #FFF;
						border-radius:50%;
						&::before {
							content:'';
							width: 8vw;
							height: 8vw;
							// background:url(../images/top/icon-calender.svg)no-repeat center center;
							// background-size: contain;
							// display:inline-block;
							// margin:0;
						}
						// &:hover {
						// 	opacity: 1;
						// 	background:$main-color;
						// 	padding:0;
						// }
					}
				}
			}

		}

		h3.top-news-section-title {
			// 		color: #fff;
			font-size: 10vw;
			// 		font-family: "Oswald", sans-serif;
			// 		font-optical-sizing: auto;
			// 		font-weight: 500;
			// 		text-align: center;
			// 		line-height: 1.1em;
			// 		margin-bottom: 2.5vw;
		}

		ul.top-news-tab-list {
			// display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 1vh 0;

			li {
				width: 50%;
				// cursor:pointer;
				padding: .5vh 0;
				border-right: none;

				p {
					font-size: 1.2rem;
					// line-height: 1em;
					padding: .3em 0;
					// font-weight: 500;
					// color: #FFF;
					text-align: center;
				}

				// &.active {
				// 	background: rgba(#FFF, .4);
				// 	color:$main-color;
				// }
				&:last-of-type {
					border-right: none;
				}
			}
		}

		ul.top-news-list {
			height: 50vh;
			overflow-y: scroll;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			li {

				// 			width: 100%;
				// 			border-bottom:1px solid #FFF;
				a {
					// 				display: block;
					// 				color: #FFF;
					margin-bottom: 1em;
					padding-top: .8em;

					span.date {
						// 					display: block;
						font-size: 1.6rem;
						// 					line-height: 1.5em;
					}

					p.title {
						font-size: 1.3rem;
						// 					line-height: 1.8em;

					}
				}

				// 			p{
				// 				color: #fff;
				// 				&.news-title{
				// 					font-size: 1.6rem;
				// 					line-height: 1.8em;
				// 				}
				// 			}
			}
		}

		// 	h3.top-news-section-title{
		// 		color: #fff;
		// 		font-size: 4.5vw;
		// 		font-family: "Oswald", sans-serif;
		// 		font-optical-sizing: auto;
		// 		font-weight: 500;
		// 		text-align: center;
		// 		line-height: 1.1em;
		// 		margin-bottom: 2.5vw;
		// 	}
		// 	ul.top-news-list{
		// 		li{
		// 			width: 100%;
		// 			border-bottom:1px solid #FFF;
		// 			a {
		// 				display: block;
		// 				color: #FFF;
		// 				margin-bottom: .5em;
		// 				padding-top: .4em;
		// 				span.date {
		// 					display: block;
		// 					font-size: 1.3rem;
		// 					line-height: 1.5em;
		// 				}
		// 				p.title {
		// 					font-size: 1.6rem;
		// 					line-height: 1.8em;

		// 				}
		// 			}
		// 			p{
		// 				color: #fff;
		// 				&.news-title{
		// 					font-size: 1.6rem;
		// 					line-height: 1.8em;
		// 				}
		// 			}
		// 		}
		// 	}
		a.news-more {
			// 		display: block;
			width: 60%;
			padding: .6vw 0;
			// 		text-align: center;
			margin: 10vw auto 0;
			// 		border: 1px solid #FFF;
			// 		color: #fff;
			// 		font-size: 1.4rem;
			// 		font-weight: 500;
			// 		position: relative;

			&::after {
				// 			content: '';
				// 			position: absolute;
				// 			background: #fff;
				width: 8vw;
				// 			height: 1px;
				// 			top: 50%;
				right: -4vw;
			}
		}

		// 	&.is-show{
		// 		h3.top-news-section-title{
		// 			animation: fade_top .5s ease .2s both;
		// 		}
		// 		ul.top-news-list{
		// 			li{
		// 				&:nth-of-type(1){
		// 					animation: fade_top .5s ease .3s both;
		// 				}
		// 				&:nth-of-type(2){
		// 					animation: fade_top .5s ease .35s both;
		// 				}
		// 				&:nth-of-type(3){
		// 					animation: fade_top .5s ease .4s both;
		// 				}
		// 				&:nth-of-type(4){
		// 					animation: fade_top .5s ease .45s both;
		// 				}
		// 				&:nth-of-type(5){
		// 					animation: fade_top .5s ease .5s both;
		// 				}
		// 			}
		// 		}
		// 		a.news-more{
		// 			animation: fade_top .5s ease .9s both;
		// 		}
		// 	}
		.top-namingrights-wrap {

			padding: 10vh 0 0;
			h3.top-namingrights-title {
				font-size: 1.8rem;
				// 			font-weight: 500;
				// 			color: #FFF;
				// 			padding-bottom: .8em;
				// 			margin-bottom: .8em;
				// 			border-bottom:1px solid #FFF;
			}

			ul.top-namingrights-list {
				// 			display: flex;
				// 			justify-content:space-between;
				flex-wrap: wrap;

				li {
					width: 48.5%;
					margin-bottom: 1.5vw;

					a {
						width: 100%;
						height: 12vw;
						// 					background: #AAA;
						// 					display: block;
						// 					img {
						// 						display: block;
						// 						width: 100%;
						// 						height: 100%;
						// 						object-fit:cover;

						// 						display: none;
						// 					}
					}
				}
			}
		}
	}

	.top-ad-section.trigger {
		padding: 6vw 8vw;

		// 	position: relative;
		// 	z-index: 2;
		h3.top-ad-section-title {
			font-size: 1.4rem;
			// 		font-weight: 500;
			// 		color: $main-color;
			// 		padding-bottom: .8em;
			// 		margin-bottom: .8em;
			// 		border-bottom:1px solid $main-color;
		}

		ul.top-ad-list {
			// 		display: flex;
			// 		flex-wrap:wrap;
			justify-content: unset;

			li {
				width: 32%;
				margin-right: 2%;
				// 			margin-bottom: 1vw;
				// 			a {
				// 				width: 100%;
				// 				height: 7vw;
				// 				background: #AAA;
				// 				display: block;
				// 				img {
				// 					display: block;
				// 					width: 100%;
				// 					height: 100%;
				// 					object-fit:cover;

				// 					display: none;
				// 				}
				// 			}
				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}
		}

		// 	&::before {
		// 		content:'';
		// 		width: 100%;
		// 		height: 100%;
		// 		position: absolute;
		// 		top: 0;
		// 		left:0;
		// 		z-index: -1;
		// 		background: rgba(#DFDFDF, .9);
		// 	}
	}



}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

	// /********************************************************************************

	// top  fix-menu

	// ********************************************************************************/

	.top-fix-menu-wrap {
		.scrolldown {
			width: 8vw;

			span {
				left: 1vw;
				font-size: 3vw;
			}
		}
	}


	.worksheet-mb-wrap {
		max-height: 500px;

		p.question {
			font-size: calc(1.4rem + 2vw);

			&::before {
				font-size: calc(2rem + 2vw);
			}
		}

		.worksheet-check-list {
			form {
				display: flex;
				flex-direction: column;
			}

			.worksheet-label {
				margin-bottom: 1vw;

				label {
					font-size: calc(1.2rem + 1vw);
				}

				span {
					font-size: calc(1.2rem + .5vw);
				}
			}
		}

		.send {
			font-size: calc(1.2rem + .5vw);
			bottom: 3vw;
		}

		.worksheet-close {
			width: 5vw;
			height: 5vw;

			&::before {
				content: '';
				width: 3vw;
				left: 1vw;
			}

			&::after {
				width: 3vw;
				left: 1vw;
			}
		}
	}

	// // /********************************************************************************

	// // top  緊急のお知らせ

	// // ********************************************************************************/

	.top-attention_topics-wrap {
		ul.top-attention_topics-list {
			li {
				margin-bottom: 1vw;

				a {
					padding: .5vw 3vw;
					font-size: calc(1.2rem + .5vw);

					span.date {
						font-size: calc(1.2rem + 1vw);
					}
				}
			}
		}
	}

	// // /********************************************************************************

	// // top  mainvisual

	// // ********************************************************************************/

	.top-mainvisual-section {
		.top-mainvisual-wrap {
			width: 62vw;
			max-width: 44vh;

			.top-mainvisual-introduction {
				padding: 4vw;

				ul.top-mainvisual-keyword {
					li {
						font-size: calc(2rem + .5vw);
					}
				}

				p.top-mainvisual-introduction-text {
					font-size: calc(1.6rem + .5vw);
				}
			}
		}

		img.top-mainvisual-university {
			width: 77vw;
			max-width: 54vh;
		}

		p.top-mainvisual-attention {
			font-size: 1.2rem;
		}
	}


	// // /********************************************************************************

	// // top  navi-section 

	// // ********************************************************************************/

	.top-navi-section {
		ul.top-target-navi-list {
			li {
				margin-bottom: 12vw;

				a {
					height: 18vh;
					min-height: 180px;

					p {
						font-size: calc(1.2rem + 1vw);
					}
				}
			}
		}
	}


	// // /********************************************************************************

	// // top  cyberpark-section 

	// // ********************************************************************************/


	.top-cyberpark-section {
		h3.top-cyberpark-title {
			width: 30%;
		}

		.top-cyberpark-article-wrap {
			.top-cyberpark-article {
				h4.top-cyberpark-article-title {
					font-size: calc(2rem + 1vw);
				}

				ul.top-cyberpark-article-list {
					li {
						a {
							p.top-cyberpark-movie-title {
								font-size: calc(1.3rem + 1vw);
							}
						}
					}

				}
			}
		}
	}



	// // /********************************************************************************

	// // top  #Find Your Dream section 

	// // ********************************************************************************/

	.top-find-section {
		.top-find-section-head {
			p {
				font-size: calc(1.2rem + 1vw);
			}
		}

		ul.top-find-word-list {
			li {
				font-size: calc(1.2rem + 1vw);
			}
		}

		.top-find-program-list-wrap {
			dl.top-find-program-list {
				dt {
					font-size: calc(1.6rem + 1vw);

					span {
						font-size: 6vw;
					}
				}

				dd {
					ul {
						li {
							a {
								font-size: calc(1rem + 1vw);
							}
						}
					}
				}
			}
		}

		.top-find-textscroll {
			p {
				svg {
					text {
						transform: translateY(5vw);
					}
				}
			}
		}
	}



	.top-news-section {
		ul.top-news-list {
			li {
				a {
					span.date {
						font-size: calc(1.6rem + 1vw);
					}

					p.title {
						font-size: calc(1.3rem + .5vw);
					}
				}
			}
		}

		a.news-more {
			padding: 3vw 0;
			font-size: calc(1.4rem + 1vw);
		}
	}

	.top-ad-section.trigger {
		h3.top-ad-section-title {
			font-size: calc(1.4rem + 1vw);
			padding-bottom: .6em;
			margin-bottom: .6em;
		}
	}




}