@charset "UTF-8";
@import "variables";


// /********************************************************************************

// top  common

// ********************************************************************************/

body.top {
	.content-wrap {
		width: 100%;
		display: block;
		z-index: 1;
		margin: 0 auto;
		position: relative;
		padding: 0;
		background: none;

		&::after {
			content: '';
			width: 100%;
			height: 57vw;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
		}
	}

	.content-inner {
		width: calc(100% - 26vw);
		margin: 0 13vw;
		padding-bottom: 70px;
		padding-top: 40px;
	}
}

// /********************************************************************************

// top  fix-menu

// ********************************************************************************/


.top-fix-menu-wrap {
	position: fixed;
	bottom: 1.2vw;
	right: 0;
	z-index: 10;
	pointer-events: none;

	.scrolldown {
		position: relative;
		width: 4vw;
		height: 20vh;
		margin: 0 0 4vw auto;
		transition: all .4s;

		span {
			position: absolute;
			display: inline-block;
			left: 5px;
			top: 0;
			color: #fff;
			font-size: 1.8rem;
			font-weight: 500;
			font-family: Oswald, sans-serif;
			letter-spacing: .1em;
			writing-mode: vertical-rl;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 1.5px;
			height: 100%;
			background: #fff;
			animation: pathmove 2s cubic-bezier(1, 0, 0, 1) infinite;
		}
	}

	ul.top-fix-menu {
		// overflow: hidden;
		pointer-events: none;

		>li {
			width: 7.5vw;
			height: 5vw;
			padding: .5vw 2.5vw .5vw .5vw;
			border-radius: 2.5vw 0 0 2.5vw;
			background: rgba(#FFF, .8);
			margin: 0 0 .8vw auto;
			cursor: pointer;
			pointer-events: auto;
			transform: translateX(2vw);
			transition: all .4s ease;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&:nth-of-type(2) {
				margin: 0 0 .6vw auto;
			}

			&:hover {
				transform: translateX(1vw);
			}

			&.ai-chat {
				&:hover {
					transform: translateX(1vw);

					p.comment {
						opacity: 1;
						left: -13vw;
					}
				}

				p.comment {
					width: 12vw;
					height: 2vw;
					border-radius: .7vw;
					position: absolute;
					left: -10vw;
					bottom: 1vw;
					background: rgba(#FFF, .8);
					color: $main-color;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.4rem;
					font-weight: 500;
					letter-spacing: .02em;
					opacity: 0;
					transition: all .4s ease;
					pointer-events:none;

					&::after {
						content: '';
						width: 2vw;
						height: 2vw;
						clip-path: polygon(1vw 0, 0 0, 0 .6vw);
						background: rgba(#FFF, .8);
						position: absolute;
						right: calc(-2vw + 1px);
						bottom: -.7vw;
						display: block;
					}
				}

				.ai-chat-box-wrap {
					width: 30vw;
					background: rgba(#FFF, .95);
					position: absolute;
					bottom: 0;
					right: 10vw;
					border-radius: 2vw;
					overflow: hidden;
					opacity: 0;
					pointer-events: none;
					transform: translateY(5vw);
					transition: all .4s ease .3s;


					.ai-chat-box-close {
						width: 2vw;
						height: 2vw;
						position: absolute;
						top: .75vw;
						right: 2vw;
						z-index: 10;
						cursor: pointer;
						transition: all .3s ease;

						&::before {
							content: '';
							background: #FFF;
							width: 2vw;
							height: 1px;
							transform: rotate(-45deg);
							position: absolute;
							top: 50%;
							left: 0;
							z-index: 1;
						}

						&::after {
							content: '';
							background: #FFF;
							width: 2vw;
							height: 1px;
							transform: rotate(45deg);
							position: absolute;
							top: 50%;
							left: 0;
							z-index: 1;
						}

						&:hover {
							transform: scale(1.4);
						}
					}

					.ai-chat-box-title {
						background: $main-color;
						color: #FFF;
						font-size: 1.8rem;
						font-weight: 500;
						line-height: 1em;
						padding: .8em 2em;
					}

					ul.ai-chat-list {
						padding: 5vh 2vw;
						max-height: 50vh;
						overflow-Y: scroll;

						li {
							width: 90%;

							p {
								padding: 1vw;
								font-size: 1.5rem;
								line-height: 1.6em;
								background: rgba($main-color, .1);
								position: relative;
							}

							&.answer {
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin: 0 10% 2vw 0;

								p {
									width: calc(100% - 4.5vw);

									&::before {
										content: '';
										width: 1.2vw;
										height: 1.2vw;
										clip-path: polygon(100% 0, 0 50%, 100% 100%);
										background: rgba($main-color, .1);
										position: absolute;
										left: -1.2vw;
										top: calc(50% - .6vw);
										display: block;

									}
								}

								&::before {
									content: '';
									width: 3vw;
									height: 3vw;
									border: 1px solid $main-color;
									border-radius: 1.5vw;
									display: block;
									background: url(../images/top/top-fix-icon02.webp)no-repeat center top;
									background-size: 130%;
								}
							}

							&.question {
								margin: 0 5% 2vw 5%;

								p {
									width: 100%;
									margin-left: auto;

									&::before {
										content: '';
										width: 1.2vw;
										height: 1.2vw;
										clip-path: polygon(0 0, 100% 50%, 0 100%);
										background: rgba($main-color, .1);
										position: absolute;
										right: -1.2vw;
										top: calc(50% - .6vw);
										display: block;

									}
								}
							}
						}
					}

					.ai-chat-box-write {
						width: 100%;
						height: 14vh;
						padding: .8vw 1.5vw;
						background: #EFEFEF;

						p {
							color: #7f7f7f;
							font-size: 1.7rem;
						}

						span {
							width: calc(100% - 3vw);
							color: #111;
							margin-top: auto;
							font-size: 1.1rem;
							line-height: 1.5em;
							letter-spacing: 0;
							display: block;
							position: absolute;
							left: 1.5vw;
							bottom: 1vw;
						}
					}

					&.is-active {
						opacity: 1;
						pointer-events: auto;
						transform: translateY(0);
					}
				}

				&.active {
					p.comment {
						opacity: 1;
						left: -13vw;
					}
				}

				&.lock {
					&:hover {
						transform: translateX(2vw);
					}
				}
			}

			&.worksheet {
				width: 20vw;
				min-height: 8vw;
				padding: .5vw .2vw .5vw 6vw;
				border-radius: .8vw 0 0 .8vw;
				margin-bottom: 0;
				transform: translateX(23.5vw);
				cursor: auto;

				img {
					width: 16vw;
					height: 6vw;
					object-fit: contain;
					position: absolute;
					left: -13vw;
					top: 0;
					cursor: pointer;
				}

				p.question {
					font-size: 1.5rem;
					line-height: 1.2em;
					font-weight: 700;
					border-bottom: 1px solid $main-color;
					padding-bottom: .2em;
					margin-bottom: .2em;

					&::before {
						content: 'Q.';
						display: inline-block;
						margin-right: .4em;
						font-size: 1.8rem;
						font-weight: 700;
						color: $main-color;
					}
				}

				.worksheet-check-list {
					display: flex;
					flex-wrap: wrap;
					opacity: 0;

					form {
						line-height: 1.3em;
					}

					.worksheet-label {
						display: inline-block;

						label {
							font-size: 1.2rem;
							font-weight: 700;
							letter-spacing: 0;
							line-height: 1.2em;
							margin-right: 1em;
						}

						input {
							margin: 0 .2vw;
						}

						input[type="text"i] {
							min-width: 30em;
							margin-left: 1em;
						}

						span {
							font-size: 1rem;
							font-weight: 500;
						}
					}
				}

				.send {
					width: 6vw;
					height: 2vw;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 1.5rem;
					font-weight: 700;
					color: #FFF;
					background: $main-color;
					position: absolute;
					bottom: .5vw;
					right: 2vw;
					border: none;
					border-radius: .4vw;
					box-shadow: 3px 2px 4px rgba(#000, .2);
				}

				.submit-text {
					display: none;

					p {
						font-size: 1.6rem;
						font-weight: 500;
						padding: .5em 0;
					}
				}

				.worksheet-close {
					width: 2.2vw;
					height: 2.2vw;
					border-radius: 50%;
					background: #FFF;
					box-shadow: 3px 2px 4px rgba(#000, .2);
					position: absolute;
					top: 1vw;
					right: 2vw;
					cursor: pointer;

					&::before {
						content: '';
						background: $main-color;
						width: 1.2vw;
						height: 1px;
						transform: rotate(-45deg);
						position: absolute;
						top: 50%;
						left: .5vw;
						z-index: 1;
					}

					&::after {
						content: '';
						background: $main-color;
						width: 1.2vw;
						height: 1px;
						transform: rotate(45deg);
						position: absolute;
						top: 50%;
						left: .5vw;
						z-index: 1;
					}

					&:hover {
						background: #FAFAFA;
					}
				}

				&:hover {
					transform: translateX(17vw);
				}

				&.active {
					width: 53vw;
					transform: translateX(0);

					.worksheet-check-list {
						opacity: 1;
						transition: all .1s ease .4s;
					}

					img {
						width: 14vw;
						left: -8vw;
					}

					&:hover {
						transform: translateX(0);
					}
				}
			}
		}
	}

	&.hide-out {
		.scrolldown {
			opacity: 0;
		}
	}
}


@keyframes pathmove {
	0% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}

	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}

	50.1% {
		transform: scale(1, 1);
		transform-origin: 0 100%;
	}

	100% {
		transform: scale(1, 0);
		transform-origin: 0 100%;
	}
}

// /********************************************************************************

// top  緊急のお知らせ

// ********************************************************************************/


.top-attention_topics-wrap {
	position: fixed;
	bottom: 5.5vw;
	left: 0;
	z-index: 10;
	transform: translateX(0);
	transition: all .2s ease;

	ul.top-attention_topics-list {
		li {
			margin-bottom: 4px;

			a {
				display: inline-flex;
				align-items: center;
				background: $yellow-color;
				padding: 0 1em 0 2vw;
				font-size: 1.5rem;
				line-height: 1.4em;
				font-weight: 700;
				min-height: 2.2vw;
				// border-radius: 0 1vw 1vw 0;
				box-shadow: 8px 4px 10px rgba(#000, .2);
				position: relative;

				span.date {
					display: inline-block;
					font-size: 1.4rem;
					font-weight: 700;
					margin-right: .5vw;
				}
			}
		}
	}

	&.hide-out {
		transform: translateX(-120%);
	}
}



// /********************************************************************************

// top  mainvisual

// ********************************************************************************/

.top-mainvisual-section {
	width: 100%;
	height: calc(100vh - 16.5vw);
	margin: 9vw auto 15vw;
	position: relative;

	.top-mainvisual-wrap {
		width: clamp(720px, 72vw, 128vh);
		height: fit-content;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		cursor: pointer;

		.top-mainvisual-bg {
			fill: $main-color;
			display: block;
			width: 100%;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
		}

		.top-mainvisual {
			display: block;
			width: 100%;
			height: auto;
			transition: all .3s ease;
		}

		.top-mainvisual-introduction {
			width: 100%;
			height: 100%;
			padding: 4vw 11vw;
			position: absolute;
			top: 50%;
			left: 50%;
			// background: rgba(#FFF, .2);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			opacity: 0;
			visibility: hidden;
			transform: translate(-50%, -40%);
			transition: all .4s ease 0s;

			p.top-mainvisual-introduction-title {
				width: 100%;
				font-size: clamp(2.5rem, 3.6rem, 5vh);
				font-weight: 500;
				color: $main-color;
				text-align: center;
				padding-bottom: 5vh;
				border-bottom: 1px solid $main-color;
			}

			ul.top-mainvisual-keyword {
				display: flex;
				align-items: center;
				// justify-content:center;
				flex-wrap: wrap;
				padding: 4vh 1vw 3vh;

				li {
					width: fit-content;
					font-size: 3rem;
					font-size: clamp(2.5rem, 3rem, 4vh);
					line-height: 1.3em;
					color: $main-color;
					margin: 0 .6em .5em 0;
					letter-spacing: 0;
				}
			}

			p.top-mainvisual-introduction-text {
				font-size: clamp(1.2rem, 1.5rem, 2vh);
				line-height: 1.6em;
				font-weight: 500;
			}
		}

		&:hover {
			.top-mainvisual-bg {
				fill: #FFF;
			}

			.top-mainvisual {
				opacity: .3;
				filter: blur(3px);
			}

			.top-mainvisual-introduction {
				opacity: 1;
				visibility: visible;
				transform: translate(-50%, -50%);
				transition: all .5s ease .3s;
			}
		}
	}


	img.top-mainvisual-university {
		display: block;
		width: clamp(800px, 80vw, 142vh);
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	p.top-mainvisual-attention {
		position: absolute;
		bottom: -6vw;
		left: 2vw;
		font-size: .7vw;
		letter-spacing: .05em;
		color: #FFF;
	}
}






// /********************************************************************************

// top  navi-section 

// ********************************************************************************/

.top-navi-section {
	width: 100%;
	padding: 3vw 8vw 12vw;

	ul.top-target-navi-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			width: 30%;
			margin-bottom: 2vw;
			transform: translateY(4vw);
			opacity: 0;
			transition: all .6s ease;

			a {
				width: 100%;
				height: 8vw;
				border-radius: 4vw;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 14% 0 10%;
				box-shadow: 8px 4px 10px rgba(#000, .2);

				span.icon {
					display: block;
					width: 30%;
					height: 100%;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				p {
					width: 70%;
					text-align: center;
					font-size: 1.8rem;
					font-weight: 600;
					line-height: 1.5em;
					color: #FFF;
				}
			}

			&.navi01 {
				order: 0;
				transition-delay: 0s;

				a {
					background: $target-color01;
				}
			}

			&.navi02 {
				order: 3;
				transition-delay: .17s;

				a {
					background: $target-color02;
				}
			}

			&.navi03 {
				order: 1;
				transition-delay: .1s;

				a {
					background: $target-color03;
				}

				transform:translateY(1.5vw);
			}

			&.navi04 {
				order: 4;
				transition-delay: .27s;

				a {
					background: $target-color04;
				}

				transform:translateY(1.5vw);
			}

			&.navi05 {
				order: 2;
				transition-delay: .2s;

				a {
					background: $target-color05;
				}
			}

			&.navi06 {
				order: 5;
				transition-delay: .37s;

				a {
					background: $target-color06;
				}
			}
		}

		&.is-show {
			li {
				opacity: 1;

				&.navi01 {
					transform: translateY(0);
				}

				&.navi02 {
					transform: translateY(0);
				}

				&.navi03 {
					transform: translateY(-2.5vw);
				}

				&.navi04 {
					transform: translateY(-2.5vw);
				}

				&.navi05 {
					transform: translateY(0);
				}

				&.navi06 {
					transform: translateY(0);
				}
			}
		}
	}


	ul.top-category-navi-list {
		padding-top: 7vw;
		display: flex;
		justify-content: space-between;

		li {
			transform: translateY(10vw);
			opacity: 0;
			transition: all .5s ease .2s;

			a {
				// display: flex;
				// flex-direction:column;
				// justify-content:center;
				// align-items:center;
				display: block;
				width: 18vw;
				height: 18vw;
				min-height: 18vw;
				border-radius: 9vw;
				background: rgba(#FFF, .45);
				box-shadow: 8px 4px 10px rgba(#000, .2);
				padding: 6vw 1vw;
				transform: translateY(0);
				transition: all .4s ease;
				position: relative;

				span.hover-title {
					display: block;
					width: 66%;
					margin: 0 auto;
					position: absolute;
					top: 50%;
					left: 17%;
					opacity: 0;
					visibility: hidden;
					transform: translateY(-5vw);
					transition: all .15s ease 0s;
				}

				p {
					width: 70%;
					position: absolute;
					top: 50%;
					left: 15%;
					transform: translateY(-50%);

					&.hover-text {
						width: 80%;
						font-size: calc(1rem + .2vw);
						font-weight: 500;
						line-height: 1.7em;
						color: #FFF;
						top: 50%;
						left: 10%;
						opacity: 0;
						visibility: hidden;
						transform: translateY(2vw);
						transition: all .15s ease 0s;
					}

					span.en {
						display: block;
						font-family: Oswald, sans-serif;
						font-optical-sizing: auto;
						font-size: 1.4vw;
						font-weight: 700;
						letter-spacing: .01em;
						line-height: 1.2em;
						text-align: center;
						color: #fff;
						padding-top: .3vw;
					}
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				&::after {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 9vw;
					z-index: -1;
					opacity: 0;
					transition: all .3s ease 0s;

				}

				&:hover {
					transform: translateY(-6vw);
					min-height: 36vw;
					opacity: 1;
					transition: all .3s ease .1s;

					span.hover-title {
						opacity: 1;
						transform: translateY(-7vw);
						transition: all .2s ease .3s;
						visibility: visible;
					}

					p {
						&.hover-text {
							opacity: 1;
							transform: translateY(4.5vw);
							transition: all .2s ease .3s;
							visibility: visible;
						}
					}

					&::after {
						opacity: 1;
						transition: all .4s ease .1s;
					}
				}
			}

			&:nth-of-type(2n - 1) {
				margin-top: 6vw;
				transform: translateY(-10vw);

				a:hover {
					transform: translateY(-12vw);
				}
			}

			&:nth-of-type(1) a::after {
				background: url(../images/top/top-category-navi01.webp)no-repeat center center;
				background-size: cover;
			}

			&:nth-of-type(2) a::after {
				background: url(../images/top/top-category-navi02.webp)no-repeat center center;
				background-size: cover;
			}

			&:nth-of-type(3) a::after {
				background: url(../images/top/top-category-navi03.webp)no-repeat center center;
				background-size: cover;
			}

			&:nth-of-type(4) a::after {
				background: url(../images/top/top-category-navi04.webp)no-repeat center center;
				background-size: cover;
			}
		}

		&.is-show {
			li {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}





// /********************************************************************************

// top  cyberpark-section 

// ********************************************************************************/


.top-cyberpark-section {
	width: 100%;
	padding: 8vw 2vw 6vw 10vw;
	// background: linear-gradient(140deg, rgba(33,80,177,.9) 0%, rgba(103,128,222,.75) 62%, rgba(17,100,168,1) 100%);
	background: linear-gradient(140deg, rgba(41, 114, 176, .9) 0%, rgba(108, 142, 232, .75) 60%, rgba(54, 110, 142, 1) 100%);
	overflow: hidden;
	position: relative;

	>* {
		position: relative;
		z-index: 1;
	}

	h3.top-cyberpark-title {
		width: 24vw;
		position: absolute;
		top: 12vw;
		opacity: 0;
		z-index: 2;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.top-cyberpark-map-wrap {
		width: 74%;
		margin-left: auto;
		position: relative;
		transform: translateY(10vw);
		opacity: 0;


		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		.top-cyberpark-tag {
			position: absolute;
			width: 7vw;
			opacity: 0;
			cursor: pointer;
			transform: translateY(-6vw);

			img {
				display: block;
				width: 60%;
				margin: 0 auto .5vw;
				transition: all .2s ease;
			}

			p {
				font-size: 1.2rem;
				line-height: 1.3em;
				background: #FFF;
				border: 1px solid #000;
				letter-spacing: 0;
				font-weight: 500;
				width: 100%;
				text-align: center;
				padding: .2em 0;
				box-shadow: 0 2px 6px rgba(0, 0, 0, .4);
			}

			&.tag01 {
				top: 19%;
				left: 69%;
			}

			&.tag02 {
				top: 46%;
				left: 63%;
			}

			&.tag03 {
				top: 33%;
				left: 20%;
			}

			&.tag04 {
				top: 44%;
				left: 38%;
			}

			&.tag05 {
				top: 23%;
				left: 35%;
			}
			&.tag06 {
				top: 13%;
				left: 49%;
			}

			&:hover {
				img {
					width: 70%;
				}
			}
		}
	}


	.top-cyberpark-article-wrap {
		width: calc(100% - 8vw);
		min-height: 38vh;
		height: 27vw;
		border-radius: 2vw;
		background: rgba(#FFF, .3);
		box-shadow: 8px 4px 10px rgba(#000, .2);
		margin-top: -13vw;
		// padding: 3vw 4vw 4vw;
		transform: translateX(-5vw);
		opacity: 0;
		position: relative;

		.top-cyberpark-article {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 3vw 4vw 4vw;

			opacity: 0;
			pointer-events: none;
			transform: translateY(10vw);
			transition: all .5s;


			h4.top-cyberpark-article-title {
				font-size: 2.2rem;
				font-weight: 500;
				color: #FFF;
				border-bottom: 1px solid #FFF;
				padding-bottom: .3em;
			}

			ul.top-cyberpark-article-list {
				padding-top: 2vw;

				li {
					margin: 0 .2vw;

					a {
						display: block;
						position: relative;

						.top-cyberpark-photo {
							position: relative;
							overflow: hidden;

							img {
								width: 100%;
								transition: all .3s;
							}
						}

						.top-cyberpark-movie-photo {
							position: relative;
							overflow: hidden;

							img {
								width: 100%;
								transition: all .3s;
							}

							&::before {
								content: '';
								position: absolute;
								background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 100%);
								width: 100%;
								height: 100%;
								top: 0;
								left: 0;
								z-index: 1;
							}


							&::after {
								content: '';
								position: absolute;
								background: url("../images/corpo/corpo-movie-icon.webp") no-repeat;
								background-size: contain;
								width: 3vw;
								height: 3vw;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								z-index: 2;
							}
						}
						.top-cyberpark-movie-text {
							// background: rgba(#000, .9);
							padding:.2vw 0 .5vw;
							p.top-cyberpark-ch-name {
								font-size: calc(.8rem + .2vw);
								font-weight: 500;
								color: #fff;
								width: 100%;
								border-bottom: 1px solid #FFF;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								overflow: hidden;
							}
							p.top-cyberpark-movie-title {
								font-size: calc(1.2rem + .2vw);
								font-weight: 500;
								line-height: 1.4em;
								letter-spacing: .02em;
								color: #fff;
								z-index: 1;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
							}
						}
						&:hover {
							opacity: 1;

							.corpo-movie-photo {
								img {
									transform: scale(1.1);
								}
							}
						}
					}
					&.tweet {
						padding:0;
						max-height: 19vw;
						width: 100%;
						overflow:hidden;

						.twitter-timeline {
							width: 100% !important;
							margin: 0 auto;
						}
						// .twitter-tweet {
						// 	margin: 0 !important;
						// 	transform:translateY(-1.5vw);
						// }
						// iframe {
						// 	// width: 100% !important;
						// 	// transform:scale(.85);
						// }
					}
				}
			}

			&.active {
				opacity: 1;
				pointer-events: auto;
				transform: translateY(0);
			}
		}
	}


	&::before {
		content: "";
		width: 0;
		height: calc(100% - 7vw);
		height: 100%;
		position: absolute;
		top: 7vw;
		left: 50%;
		z-index: 0;
		display: block;
		border-radius: 2vw 2vw 0 0;
		background: rgba(#FFF, .35);
		box-shadow: 8px 4px 10px rgba(#000, .2);
		opacity: 0;
	}

	&.is-show {
		h3.top-cyberpark-title {
			opacity: 1;
			transition: all .7s ease 1s;
		}

		.top-cyberpark-map-wrap {
			transform: translateY(0);
			opacity: 1;
			transition: all .5s ease-out .8s;

			.top-cyberpark-tag {
				opacity: 1;
				transform: translateY(0);
				transition: all .3s ease 1.3s;

				&.tag01 {
					transition-delay: 1.3s;
				}

				&.tag02 {
					transition-delay: 1.4s;
				}

				&.tag03 {
					transition-delay: 1.5s;
				}

				&.tag04 {
					transition-delay: 1.6s;
				}

				&.tag05 {
					transition-delay: 1.7s;
				}
			}
		}

		.top-cyberpark-article-wrap {
			opacity: 1;
			transform: translateX(0);
			transition: all .5s ease 2s;
		}

		&::before {
			content: "";
			left: 5%;
			width: 90%;
			opacity: 1;
			transition: all .3s ease .3s;
		}
	}
}


.slide-arrow {
	position: absolute;
	top: 50%;
	z-index: 1;
	cursor: pointer;
	transition: all .4s;

	span {
		position: relative;
		display: block;
		border: solid 1px #fff;
		width: 30px;
		height: 30px;
		transition: all .4s;

		&::before {
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			top: 44%;
			transform: translateY(-50%);
			transition: all .4s;
		}
	}

	&.prev-arrow {
		left: -4%;

		span {
			&::before {
				right: 10px;
				border-left: solid 1px #fff;
				border-bottom: solid 1px #fff;
				transform: rotate(45deg) translateY(-50%);
			}
		}
	}

	&.next-arrow {
		right: -4%;

		span {
			&::before {
				left: 3px;
				border-top: solid 1px #fff;
				border-right: solid 1px #fff;
				transform: rotate(45deg) translateY(-50%);
			}
		}
	}

	&:hover {
		span {
			background: #fff;
		}

		&.prev-arrow {
			span {
				&::before {
					border-left: solid 1px $main-color;
					border-bottom: solid 1px $main-color;
				}
			}
		}

		&.next-arrow {
			span {
				&::before {
					border-top: solid 1px $main-color;
					border-right: solid 1px $main-color;
				}
			}
		}
	}
}


// /********************************************************************************

// top  #Find Your Dream section 

// ********************************************************************************/

.top-find-section {
	width: 100%;
	padding: 18vw 6vw;
	background: #FFF;
	position: relative;

	.top-find-section-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 8vw;

		h3.top-find-title {
			width: 55%;
			color: $main-color;
			font-size: 2.3rem;
			font-weight: 500;
			line-height: 1.7em;

			span {
				display: block;
				font-family: "Oswald", sans-serif;
				font-optical-sizing: auto;
				font-weight: 500;
				font-size: 4.5vw;
				line-height: 1.2em;
				letter-spacing: .08em;
			}
		}

		p {
			width: 43%;
			color: $main-color;
			font-size: 2rem;
			font-weight: 500;
			line-height: 1.7em;
			padding-top: .2em;
		}
	}

	ul.top-find-word-list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		padding: 4vw 0;

		li {
			width: fit-content;
			height: 3vw;
			border-radius: 1.5vw;
			padding: 0 1.5em;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #FFF;
			font-size: 1.8rem;
			line-height: 1em;
			letter-spacing: .02em;
			font-weight: 500;
			margin: 0 2vw .8vw 0;
			cursor: pointer;
			background: linear-gradient(110deg, rgba(118, 157, 231, 1) 0%, rgba(95, 100, 203, 1) 35%, rgba(111, 147, 207, 1) 60%, rgba(102, 125, 214, 1) 100%);
			transition: all .3s ease;

			&::before {
				content: '#';
				display: inline-block;
				margin-right: .3em;
			}

			&:hover {
				transform: scale(1.08);
			}

			&.is-active {
				background: $main-color;
			}
		}

	}

	.top-find-program-list-wrap {
		display: flex;
		justify-content: space-between;
		padding: 2vw 4vw 0;

		dl.top-find-program-list {
			width: 31%;
			border: 1px solid $main-color;
			padding: 2.5vw 2vw 2vw;
			position: relative;

			dt {
				width: 75%;
				font-size: calc(1.4rem + .4vw);
				font-weight: 500;
				text-align: center;
				color: $main-color;
				position: absolute;
				top: -1em;
				left: 50%;
				transform: translateX(-50%);
				background: #FFF;

				span {
					display: inline-block;
					font-size: 4rem;
					font-family: "Oswald", sans-serif;
					font-optical-sizing: auto;
					font-weight: 500;
					margin-right: .05em;
				}
			}

			dd {
				ul {
					li {
						margin-bottom: .8em;

						a {
							font-size: 1.6rem;
							line-height: 1.6em;
							letter-spacing: .02em;
							font-weight: 500;
							display: block;
							padding-left: 1.3vw;
							padding-bottom: .3em;
							position: relative;
							opacity: .1;
							pointer-events: none;
							transition: all .4s ease;

							&::before {
								content: '';
								display: inline-block;
								width: .8vw;
								height: .8vw;
								border-radius: 50%;
								background: $main-color;
								margin-right: .5vw;
								margin-left: -1.3vw;
							}

							&::after {
								content: '';
								width: 0;
								height: 1px;
								background: $main-color;
								position: absolute;
								left: 1em;
								bottom: 0;
								transition: all .4s ease;
							}

							&:hover {
								opacity: 1;
								color: $main-color;
								padding-left: 1.7vw;
							}
						}

						&.show a {
							opacity: 1;
							pointer-events: auto;

							&::before {
								content: '';
								display: inline-block;
								width: .8vw;
								height: .8vw;
								border-radius: 50%;
								background: $main-color;
								margin-right: .5vw;
								margin-left: -1.3vw;
							}

							&::after {
								content: '';
								width: calc(100% - 1em);
							}
						}
					}
				}
			}
		}
	}

	.top-find-textscroll {
		width: 100%;
		padding: 2px 0;
		overflow: hidden;
		position: absolute;
		left: 0;
		pointer-events: none;
		opacity: .8;

		&.scroll-position01 {
			top: 5vw;
		}

		&.scroll-position02 {
			bottom: 3vw;
		}

		p {
			width: 170vw;
			height: 12vw;

			svg {
				width: 170vw;
				height: 12vw;
				display: block;

				text {
					width: 100%;
					height: 100%;
					font-size: 6vw;
					letter-spacing: .2em;
					line-height: 1em;
					font-family: "Oswald", sans-serif;
					font-optical-sizing: auto;
					font-weight: 500;
					white-space: nowrap;
					// transform: translateY(-3vw);
				}
			}

			&.textscroll01 {
				transform: translateX(0);
				animation: slide-move01 40s linear infinite;
			}

			&.textscroll02 {
				margin-top: -12vw;
				transform: translateX(0);
				animation: slide-move02 40s linear infinite;
			}
		}
	}
}


@keyframes slide-move01 {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(-170vw);
		opacity: 1;
	}

	50.001% {
		transform: translateX(-170vw);
		opacity: 0;
	}

	50.002% {
		transform: translateX(170vw);
		opacity: 0;
	}

	50.003% {
		transform: translateX(170vw);
		opacity: 1;
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes slide-move02 {
	0% {
		transform: translateX(170vw);
	}

	50% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-170vw);
	}
}




.top-news-section {
	padding: 8vw 10vw 7vw;

	.top-news-wrap {
		width: 100%;
		padding: 4vw 14vw 6vw;
		position: relative;
		z-index: 2;

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			border-radius: 100vw;
			background: rgba(#FFF, .35);
			// box-shadow: 8px 4px 10px rgba(#000, .2);
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
		}

		// &::before {
		// 	content:'';
		// 	width: 50.03%;
		// 	height: 100%;
		// 	clip-path: xywh(0 0 100% 100% round 50% 0 0 50%);
		// 	background: rgba(#FFF, .35);
		// 	// box-shadow: 8px 4px 10px rgba(#000, .2);
		// 	position: absolute;
		// 	top: 0;
		// 	left:0;
		// 	z-index: -1;
		// }
		// &::after {
		// 	content:'';
		// 	width: 50%;
		// 	height: 100%;
		// 	clip-path: xywh(0 0 100% 100% round 0 50% 50% 0);
		// 	background: rgba(#FFF, .35);
		// 	// box-shadow: 8px 4px 10px rgba(#000, .2);
		// 	position: absolute;
		// 	top: 0;
		// 	right:0;
		// 	z-index: -1;
		// }
		.event-calender-link {
			position: absolute;
			bottom:-2vw;
			left:calc(50% - 12vw);
			a {
				width: 24vw;
				height: 4vw;
				display:flex;
				justify-content: center;
				align-items: center;
				font-size: 1.8rem;
				font-weight: 500;
				letter-spacing:.02em;
				color:#FFF;
				line-height: 1em;
				background:$main-color;
				border:1px solid #FFF;
				border-radius:.5vw;
				&::before {
					content:'';
					width: 1.7em;
					height: 1.7em;
					background:url(../images/top/icon-calender.svg)no-repeat center center;
					background-size: contain;
					display:inline-block;
					margin-right:.3em;
				}
				&:hover {
					opacity: 1;
					background:$main-color;
					padding-left:.5vw;
				}
			}
			&.sub {
				bottom:inherit;
				left:inherit;
				top:10vw;
				right:0;
				transition: all .3s ease;
				&:hover {
					transform:scale(1.1);
				}
				a {
					width: 10vw;
					height: 10vw;
					display:flex;
					flex-direction: column;
					font-size: 1.6rem;
					font-weight: 500;
					letter-spacing:.02em;
					text-align: center;
					color:#FFF;
					line-height: 1.3em;
					background:$main-color;
					border:1px solid #FFF;
					border-radius:5vw;
					&::before {
						content:'';
						width: 3vw;
						height: 3vw;
						background:url(../images/top/icon-calender.svg)no-repeat center center;
						background-size: contain;
						display:inline-block;
						margin:0;
					}
					&:hover {
						opacity: 1;
						background:$main-color;
						padding:0;
					}
				}
			}
		}
	}

	h3.top-news-section-title {
		color: #fff;
		font-size: 4.5vw;
		font-family: "Oswald", sans-serif;
		font-optical-sizing: auto;
		font-weight: 500;
		text-align: center;
		line-height: 1.1em;
		margin-bottom: 2.5vw;
	}

	ul.top-news-tab-list {
		display: flex;
		justify-content: center;
		padding-bottom: 1em;

		li {
			cursor: pointer;
			padding: 0 .7vw;
			border-right: 1px solid #FFF;

			p {
				font-size: 1.5rem;
				line-height: 1em;
				padding: .3em .4em;
				font-weight: 500;
				color: #FFF;
			}

			&.active {
				background: rgba(#FFF, .4);
				color: $main-color;
			}

			&:last-of-type {
				border-right: none;
			}
		}
	}

	h3.top-news-section-title {
		color: #fff;
		font-size: 4.5vw;
		font-family: "Oswald", sans-serif;
		font-optical-sizing: auto;
		font-weight: 500;
		text-align: center;
		line-height: 1.1em;
		margin-bottom: 2.5vw;
	}


	.top-news-list-wrap {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		transform: translateY(5vw);

		ul.top-news-list {
			li {
				width: 100%;
				border-bottom: 1px solid #FFF;

				a {
					display: block;
					color: #FFF;
					padding: 2vh .5vw;

					.news-header {
						display: flex;
						align-items: center;
						justify-content: flex-start;

						.date {
							display: block;
							font-size: 1.3rem;
							line-height: 1.5em;
							margin-right: 1em;
						}

						.cat {
							font-size: 1.3rem;
							font-weight: 500;
							line-height: 1em;
							padding: .2em .5em .3em;
							background: $main-color;
							color: #FFF;
						}
					}

					p.title {
						font-size: 1.6rem;
						line-height: 1.4em;

					}
					&.no-link {
						pointer-events:none;
					}
				}

				p {
					color: #fff;

					&.news-title {
						font-size: 1.6rem;
						line-height: 1.8em;
					}
				}
			}
		}

		a.news-more {
			display: block;
			width: 32%;
			padding: .8vw 0;
			text-align: center;
			margin: 3vw auto 0;
			border: 1px solid #FFF;
			color: #fff;
			font-size: 1.4rem;
			font-weight: 500;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				background: #fff;
				width: 4vw;
				height: 1px;
				top: 50%;
				right: -2vw;
			}
		}

		&.active {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			transform: translateY(0);
			position: relative;
			transition: all .3s ease;
		}
	}


	&.is-show {
		h3.top-news-section-title {
			animation: fade_top .5s ease .2s both;
		}

		.top-news-list-wrap {
			ul.top-news-list {
				li {
					&:nth-of-type(1) {
						animation: fade_top .5s ease .3s both;
					}

					&:nth-of-type(2) {
						animation: fade_top .5s ease .35s both;
					}

					&:nth-of-type(3) {
						animation: fade_top .5s ease .4s both;
					}

					&:nth-of-type(4) {
						animation: fade_top .5s ease .45s both;
					}

					&:nth-of-type(5) {
						animation: fade_top .5s ease .5s both;
					}
				}
			}

			a.news-more {
				animation: fade_top .5s ease .9s both;
			}
		}
	}

	.top-namingrights-wrap {
		padding: 6vw 0 0;

		h3.top-namingrights-title {
			font-size: 2.2rem;
			font-weight: 500;
			color: #FFF;
			padding-bottom: .8em;
			margin-bottom: .8em;
			border-bottom: 1px solid #FFF;
		}

		ul.top-namingrights-list {
			display: flex;
			justify-content: space-between;

			li {
				width: 24%;

				a {
					width: 100%;
					height: 9vw;
					background: #AAA;
					display: block;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;

						display: none;
					}
				}
			}
		}
	}
}

.top-ad-section.trigger {
	padding: 4vw 10vw 5vw;
	position: relative;
	z-index: 2;

	h3.top-ad-section-title {
		font-size: 2.2rem;
		font-weight: 500;
		color: $main-color;
		padding-bottom: .8em;
		margin-bottom: .8em;
		border-bottom: 1px solid $main-color;
	}

	ul.top-ad-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			width: 24%;
			margin-bottom: 1vw;

			a {
				width: 100%;
				height: 7vw;
				background: #AAA;
				display: block;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;

					display: none;
				}
			}
		}
	}

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background: rgba(#DFDFDF, .9);
	}
}




















@import "top-res";